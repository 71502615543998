<template>
    <b-nav-item
        style="width: 6rem"
        @click="$i18n.locale = $i18n.locale === 'cn' ? 'en' : 'cn'"
    >
        <feather-icon
            icon="GlobeIcon"
            :style="
                'color:' +
                ($store.state.appConfig.layout.skin === 'dark'
                    ? '#d0d2d6'
                    : '#5e5873')
            "
            size="21"
        />

        <p
            :style="
                'color:' +
                ($store.state.appConfig.layout.skin === 'dark'
                    ? '#d0d2d6'
                    : '#5e5873')
            "
            class="position-relative m-0 d-inline text-bold"
            style="top: 2px"
        >
            {{ currentLocale.name }}
            <!-- </p> -->
        </p>
    </b-nav-item>
</template>
<script>
import { BDropdownItem, BImg, BNavItemDropdown } from "bootstrap-vue";
import {
    computed,
    getCurrentInstance,
    reactive,
    toRefs,
} from "@vue/composition-api";

export default {
    components: {
        BNavItemDropdown,
        BDropdownItem,
        BImg,
    },
    // ! Need to move this computed property to comp function once we get to Vue 3
    setup() {
        const vm = getCurrentInstance().proxy;
        const event = reactive({
            locales: [
                {
                    locale: "cn",
                    img: require("@/assets/images/flags/cn.png"),
                    name: "中文",
                },
                {
                    locale: "en",
                    img: require("@/assets/images/flags/en.png"),
                    name: "ENG",
                },
            ],
            currentLocale: computed(() => {
                return event.locales.find((l) => l.locale === vm.$i18n.locale);
            }),
        });
        return {
            ...toRefs(event),
        };
    },
};
</script>

<style></style>
